<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['wrinkles'])">
      <single-answer-question
        id="face-wrinkles"
        title="label.wrinklesToSoften"
        :value="wrinkles"
        :options="$options.faceWrinklesOptions"
        dysfunction="skin-appearance"
        @input="onFieldChange('wrinkles', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { FACE_WRINKLES_OPTIONS } from '@/modules/questionnaire/constants/steps/skinAppearance';

export default {
  name: 'WrinklesTemplate',
  components: { SingleAnswerQuestion, QuestionWithErrorWrap },
  mixins: [stepTemplateMixin],
  faceWrinklesOptions: FACE_WRINKLES_OPTIONS,
  props: {
    wrinkles: {
      type: String,
      required: true
    }
  }
};
</script>
